import React from "react";
import Container from "../components/container";
import Intro from "../components/intro";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Project from "../components/project";

export default function Projects({ pageContext, data: { site, main, project } }) {

  const pc = pageContext

    return (
        <Container>
            <HelmetDatoCms seo={main.seo} favicon={site.favicon} />
            <Intro pageContext={pc} />
            <div className="md:columns-3 gap-4 mb-3 w-full">
            {project.nodes.map(p => (
                    <Project key={p.id} text={p.text} photo={p.photo.small} />
                )
            )}
            </div>
        </Container>
    );
}

export const query = graphql`
  query page($locale: String!){
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    main: datoCmsMain {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    project: allDatoCmsProject(
        filter: {locale: {eq: $locale}}
        sort: {order: ASC, fields: position}
    ) {
      nodes {
        id
        text
        photo {
          small: gatsbyImageData(width: 760, imgixParams: { fm: "jpg" })
        }
      }
    }
  }
`;
