import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Project({ id, text, photo }) {
    return (
        <div className="bg-slate-100 p-3 mb-3 rounded break-inside-avoid-column">
            <GatsbyImage className="w-full rounded" image={photo} alt="photo" />
            <div dangerouslySetInnerHTML={{ __html: text }} ></div>
        </div>
    );
}
